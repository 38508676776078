import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { environment } from '../../../../environments/environment';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { ScheduleFormService } from './schedule-form.service';
import { lastValueFrom } from 'rxjs';
import { RegionService } from '../../services/region.service';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

interface ScheduleData {
  agendamento_id: number;
  status: boolean;
};

@Component({
  selector: 'app-schedule-form',
  standalone: true,
  providers: [provideNativeDateAdapter(),  provideNgxMask(), ScheduleFormService, RegionService],
  imports: [
    ButtonComponent,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    CommonModule,
    MatRadioModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    NgxMaskDirective
  ],
  templateUrl: './schedule-form.component.html',
  styleUrl: './schedule-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class ScheduleFormComponent {

  schedulingStep: number = 1;
  scheduleForm!: FormGroup;
  currentSchedule!: ScheduleData;
  hasError: boolean = false;
  errorMessage: string = '';

  constructor(readonly fb: FormBuilder, 
    readonly cdr: ChangeDetectorRef,
    readonly scheduleFormService: ScheduleFormService, 
    readonly regionService: RegionService) { }

  ngOnInit() {
    this.scheduleForm = this.fb.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      product: ['', Validators.required],
      zipcode: ['', Validators.required],
      address: ['', Validators.required],
      number: ['', Validators.required],
      complement: [''],
      neighborhood: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      defect: ['', Validators.required],
      warranty: ['', Validators.required],
      period: ['', Validators.required],
      visitDate: ['', Validators.required]
    });

    this.scheduleForm.get('zipcode')?.valueChanges.subscribe((value) => {
      this.regionService.getRegion(value).subscribe((response) => {
          this.scheduleForm.get('address')?.setValue(response.logradouro);
          this.scheduleForm.get('neighborhood')?.setValue(response.bairro);
          this.scheduleForm.get('city')?.setValue(response.localidade);
          this.scheduleForm.get('state')?.setValue(response.uf);


      });
    });
  }

  loading: boolean = false;

  data: any = {
    title: `Agende o conserto da sua ${environment.content.product} ${environment.content.brand} agora!`,
    subtitle: `Preencha o formulário abaixo e aguarde, enviaremos um técnico até o seu endereço.`,
  }

  products: any[] = [
    {
      value: 'Lava e Seca',
      label: 'Lava e Seca'
    },
    {
      value: 'Lavadora',
      label: 'Lavadora'
    },
    {
      value: 'Secadora',
      label: 'Secadora'
    },
    {
      value: 'Máquina de Lavar',
      label: 'Máquina de Lavar'
    }
  ];

  defects: any[] = [
    {
      value: 'Não liga',
      label: 'Não liga'
    },
    {
      value: 'Está Trepidando',
      label: 'Está Trepidando'
    },
    {
      value: 'Porta quebrada',
      label: 'Porta quebrada'
    },
    {
      value: 'Vaza agua',
      label: 'Vaza agua'
    },
    {
      value: 'Mangueira rasgada ou furada',
      label: 'Mangueira rasgada ou furada'
    },
    {
      value: 'Não Centrifuga',
      label: 'Não Centrifuga'
    },
    {
      value: 'Não solta água',
      label: 'Não solta água'
    },
    {
      value: 'Não faz o processo',
      label: 'Não faz o processo'
    },
    {
      value: 'Instalação',
      label: 'Instalação'
    },
    {
      value: 'Rasga roupas',
      label: 'Rasga roupas'
    },
    {
      value: 'Barulho ao Centrifugar',
      label: 'Barulho ao Centrifugar'
    },
    {
      value: 'Barulho na Secagem',
      label: 'Barulho na Secagem'
    },
    {
      value: 'Apresenta Erro na Secagem',
      label: 'Apresenta Erro na Secagem'
    },
  ];

  periods: any[] = [
    {
      value: '9:00 horas às 17:00 horas',
      label: '9:00 horas às 17:00 horas'
    },
    {
      value: '9:00 horas às 13:00 horas',
      label: '9:00 horas às 13:00 horas'
    },
    {
      value: '12:00 horas às 17:00 horas',
      label: '12:00 horas às 17:00 horas'
    },
    {
      value: '13:00 horas às 17:00 horasS',
      label: '13:00 horas às 17:00 horasS'
    }
  ];

  filter = (d: Date | null): boolean => {
    if (!d) {
      return false;
    }
    const day = d.getDay();
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return d >= today && day !== 0;
  };

  async startSchedule() {
    
    if (!this.validateStep()) {
      return;
    }

    this.loading = true;
    this.currentSchedule = await lastValueFrom(this.scheduleFormService.saveSchedule(this.scheduleForm.value));

    if (!this.currentSchedule.status) {
      return;
    }

    this.schedulingStep++;
    this.loading = false;
    this.cdr.detectChanges(); 
  }

  nextStep() {

    if (!this.validateStep()) {
      return;
    }

    this.schedulingStep++;
  }

  previousStep() {
    this.schedulingStep--;
  }

  validateStep() {

    let errors = 0;
    if (this.schedulingStep == 1) {
      if (this.scheduleForm.get('name')?.invalid) {
        this.scheduleForm.get('name')?.markAsTouched();
        errors++;
      }

      if (this.scheduleForm.get('phone')?.invalid) {
        this.scheduleForm.get('phone')?.markAsTouched();
        errors++;
      }

      if (this.scheduleForm.get('email')?.invalid) {
        this.scheduleForm.get('email')?.markAsTouched();
        errors++;
      }

      if (this.scheduleForm.get('product')?.invalid) {
        this.scheduleForm.get('product')?.markAsTouched();
        errors++;
      }
    }

    if (this.schedulingStep == 2) {
      if (this.scheduleForm.get('zipcode')?.invalid) {
        this.scheduleForm.get('zipcode')?.markAsTouched();
        errors++;
      }

      if (this.scheduleForm.get('address')?.invalid) {
        this.scheduleForm.get('address')?.markAsTouched();
        errors++;
      }

      if (this.scheduleForm.get('number')?.invalid) {
        this.scheduleForm.get('number')?.markAsTouched();
        errors++;
      }

      if (this.scheduleForm.get('neighborhood')?.invalid) {
        this.scheduleForm.get('neighborhood')?.markAsTouched();
        errors++;
      }

      if (this.scheduleForm.get('complement')?.invalid) {
        this.scheduleForm.get('complement')?.markAsTouched();
        errors++;
      }

      if (this.scheduleForm.get('city')?.invalid) {
        this.scheduleForm.get('city')?.markAsTouched();
        errors++;
      }
    }

    if (this.schedulingStep == 3) {
      if (this.scheduleForm.get('defect')?.invalid) {
        this.scheduleForm.get('defect')?.markAsTouched();
        errors++;
      }

      if (this.scheduleForm.get('warranty')?.invalid) {
        this.scheduleForm.get('warranty')?.markAsTouched();
        errors++;
      }
    }

    if (this.schedulingStep == 4) {
      if (this.scheduleForm.get('period')?.invalid) {
        this.scheduleForm.get('period')?.markAsTouched();
        errors++;
      }

      if (this.scheduleForm.get('visitDate')?.invalid) {
        this.scheduleForm.get('visitDate')?.markAsTouched();
        errors++;
      }
    }

    if (errors <= 0) {
      return true;
    }

    return false;
  }

  onSubmit() {
    if (this.scheduleForm.valid) {
      this.loading = true;
      
      this.scheduleFormService.generateSchedule({
        idSchedule: this.currentSchedule.agendamento_id,
        ...this.scheduleForm.value
      }).subscribe((response) => {
        if (response.status) {
          this.schedulingStep = 5;
          this.loading = false;
          this.cdr.detectChanges();
        } else {
          this.loading = false;
          this.hasError = true;
          this.errorMessage = response.alert;
          this.cdr.detectChanges();
        }
      });
    }
  }
}
