import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private activeTheme: string = '';

  constructor(@Inject(DOCUMENT) readonly document: Document) { }

  setTheme(theme: string) {

    this.activeTheme = theme;

    const body = this.document.getElementsByTagName('body')[0];


    body.classList.add(`theme-${theme}`);
    this.updateFavicon(theme);
    this.updateOgImage(theme);
    this.updateOgLogo(theme);

  }

  getTheme() {
    return this.activeTheme;
  }

  private updateFavicon(theme: string) {
    let favicon = this.document.querySelector("link[rel*='icon']") as HTMLLinkElement;
    if (!favicon) {
      favicon = this.document.createElement('link') as HTMLLinkElement;
      favicon.rel = 'shortcut icon';
      this.document.getElementsByTagName('head')[0].appendChild(favicon);
    }
    favicon.type = 'image/x-icon';
    favicon.href = `assets/theme/${theme}/favicon.png`; // Adjust the path as needed
  }

  private updateOgImage(theme: string) {
    let ogImage = this.document.querySelector("meta[property='og:image']") as HTMLMetaElement;
    if (!ogImage) {
      ogImage = this.document.createElement('meta') as HTMLMetaElement;
      ogImage.setAttribute('property', 'og:image');
      this.document.getElementsByTagName('head')[0].appendChild(ogImage);
    }
    ogImage.content = `assets/theme/${theme}/logo-image.png`; // Adjust the path as needed
  }

  private updateOgLogo(theme: string) {
    let ogLogo = this.document.querySelector("meta[property='og:logo']") as HTMLMetaElement;
    if (!ogLogo) {
      ogLogo = this.document.createElement('meta') as HTMLMetaElement;
      ogLogo.setAttribute('property', 'og:logo');
      this.document.getElementsByTagName('head')[0].appendChild(ogLogo);
    }
    ogLogo.content = `assets/theme/${theme}/logo-image.png`; // Adjust the path as needed
  }

}
